import { graphql, useStaticQuery } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import React from 'react'
import MetaImage from '../assets/images/meta/software-engineering-intelligence.png'
import GartnerTestimonials from '../components/GartnerTestimonials'
import Layout from '../components/Layout'
import CTABlock from '../components/blocks/CTABlock'
import { DarkLogoBlock } from '../components/blocks/DarkLogoBlock'
import HeroBlock from '../components/blocks/HeroBlock'
import LeadBlock from '../components/blocks/LeadBlock'
import MosaicBlock from '../components/blocks/MosaicBlock'
import QuoteBlock from '../components/blocks/QuoteBlock'
import VideoBlock from '../components/blocks/VideoBlock'
import Box from '../components/primitives/Box'
import IllustrationBlock from '../components/blocks/IllustrationBlock'
import { responsiveScale } from '../styles/helpers'

export const query = graphql`
  {
    backgroundImage: file(
      relativePath: { eq: "images/product/business-outcomes/hero.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, quality: 99)
      }
    }
    lead: file(relativePath: { eq: "images/lead/surveys-and-metrics.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1100)
      }
    }
    lead2: file(
      relativePath: { eq: "images/product/working-agreements/lead.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1440)
      }
    }
    mosaicRight1: file(
      relativePath: { eq: "images/mosaic/investment-balance-650-500.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 650)
      }
    }
    mosaicLeft1: file(
      relativePath: { eq: "images/mosaic/initiatives-650-500.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 650)
      }
    }
    mosaicRight2: file(
      relativePath: { eq: "images/mosaic/surveys-650-500.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 650)
      }
    }
    mosaicLeft2: file(
      relativePath: { eq: "images/mosaic/issue-summaries-650-500.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 650)
      }
    }
    videoThumbnail: file(
      relativePath: {
        eq: "images/product/overview/product-overview-thumbnail.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1280)
      }
    }
  }
`

const SEIPage = () => {
  const data = useStaticQuery(query)

  return (
    <Layout
      title="The all-in-one software engineering intelligence platform"
      variant="dark"
      isNew
      description="Build a truly data-driven software organization by combining insights on business outcomes, developer productivity, and developer experience."
      metaImage={MetaImage}
    >
      <HeroBlock
        title={<>The all-in-one software engineering intelligence platform</>}
        content="Build a truly data-driven software organization by combining insights on business outcomes, developer productivity, and developer experience."
        backgroundImage={getImage(data.backgroundImage)!}
      />
      <Box backgroundColor="black" paddingY={40} paddingX={{ xs: 20, md: 36 }}>
        <DarkLogoBlock hideBackground />
      </Box>
      <LeadBlock
        heading={<>Rethink developer productivity</>}
        content="Start measuring research-backed organization- and team-level productivity metrics, including SPACE and DORA metrics."
      />
      <Box>
        <IllustrationBlock
          paddingTop={responsiveScale(32)}
          image={getImage(data.lead)!}
          noPlaceHolder
          rounded
          shadow
        />
      </Box>
      <QuoteBlock person="walter" />
      <MosaicBlock
        title={<>See where engineering time&nbsp;goes</>}
        content={
          <>
            To improve the effectiveness of your engineering organization, you
            must first understand where teams are currently spending their time.
            <br />
            <br />
            The investment balance view in Swarmia allows you to create multiple
            investment categories to see how time is spent between different
            types of work, different initiatives, unplanned vs. planned work,
            and more.
          </>
        }
        image={getImage(data.mosaicRight1)!}
        imageAlign="right"
      />
      <MosaicBlock
        title={<>Deliver cross-team initiatives&nbsp;with confidence</>}
        content={
          <>
            Keeping track of multiple ongoing initiatives can be time-consuming.
            Relying on manual updates from teams often produces unreliable
            information about project delivery timelines.
            <br />
            <br />
            Swarmia allows you to stay on top of ongoing initiatives so you can
            quickly spot at-risk work and course correct before it’s too late.
          </>
        }
        image={getImage(data.mosaicLeft1)!}
      />
      <MosaicBlock
        title={<>Run developer experience&nbsp;surveys</>}
        content={
          <>
            Complement quantitative engineering metrics with qualitative
            feedback from developer experience surveys.
            <br />
            <br />
            This way, you can use the metrics to identify problem areas and the
            survey responses to come up with concrete action points.
          </>
        }
        image={getImage(data.mosaicRight2)!}
        imageAlign="right"
      />
      <MosaicBlock
        title={<>Push relevant insights to&nbsp;teams</>}
        content={
          <>
            Instead of expecting every engineer to proactively find the data
            they need to improve, teams can subscribe to useful recaps in Slack.
            <br />
            <br />
            The issue summary notification, for example, is a good reminder to
            reflect on what went well — and what could’ve gone better — in a
            completed project.
          </>
        }
        image={getImage(data.mosaicLeft2)!}
      />
      <QuoteBlock person="maryanna" />
      <LeadBlock
        heading={<>Put continuous improvement&nbsp;on&nbsp;autopilot</>}
        content={
          <>
            Empower teams to set their own working agreements based on what’s
            relevant and achievable for them.
          </>
        }
        alt="Working agreements"
        image={getImage(data.lead2)}
      />
      <CTABlock title="Get started with Swarmia today." />
      <LeadBlock
        heading="See Swarmia in action"
        content="Watch our product overview to understand how some of the best software teams in the world use Swarmia to ship better software faster."
      />
      <VideoBlock
        paddingTop={responsiveScale(64)}
        paddingBottom={responsiveScale(64)}
        thumbnail={getImage(data.videoThumbnail)!}
        videoId="ZHcQO20bL4w"
      />
      <Box
        // This is needed for the styles to work
        className="main-body-old-styles"
      >
        <GartnerTestimonials />
      </Box>
      <CTABlock
        title={<>Ready to dive into software engineering&nbsp;intelligence?</>}
      />
    </Layout>
  )
}

export default SEIPage
